<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

      <section class="md-section" id="cart">
        <div class="container">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="8">
                <div class="invisible-box">
                    <h5 style="padding-bottom: 1rem"><strong>Mis Datos</strong></h5>

                    <ion-grid>
                      <ion-row>
                        <ion-col size="12" size-lg="6">
                          <label for="firstName">Nombre*</label>
                          <InputText class="input secondary-input" id="firstName" type="text" v-model="order.firstName" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="lastName">Apellidos*</label>
                          <InputText class="input secondary-input" id="lastName" type="text" v-model="order.lastName" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="email">Email*</label>
                          <InputText class="input secondary-input" id="email" type="text" v-model="order.email" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="phone">Teléfono</label>
                          <InputText class="input secondary-input" id="phone" type="text" v-model="order.phone" />
                        </ion-col>
                        <ion-col size="12">
                          <h5 style="padding: 1rem 0"><strong>Dirección de Entrega</strong></h5>
                          <label for="direction">Dirección*</label>
                          <InputText class="input secondary-input" id="direction" type="text" v-model="order.direction" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="city">Ciudad*</label>
                          <InputText class="input secondary-input" id="city" type="text" v-model="order.city" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="zipCode">Código Postal*</label>
                          <InputText class="input secondary-input" id="zipCode" type="text" v-model="order.zipCode" />
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col size="12" class="resumen">
                          <ion-item lines="none" class="no-ripple">
                            <ion-checkbox slot="start" v-model="createAccount" color="dark"></ion-checkbox>
                            <h6>¿Crear una cuenta con estos datos?</h6>
                          </ion-item>
                        </ion-col>
                        <ion-col size="12" size-lg="6" v-if="createAccount">
                          <label for="newPassword">Contraseña*</label>
                          <Password v-model="newPassword" class="input" id="newPassword" :feedback="true" toggleMask></Password>   
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col size="12" class="resumen">
                          <ion-item lines="none" class="no-ripple">
                            <ion-checkbox slot="start" v-model="billingData" color="dark"></ion-checkbox>
                            <h6>Utilizar otros datos de facturación</h6>
                          </ion-item>
                        </ion-col>
                      </ion-row >

                      <ion-row v-if="billingData">
                        <ion-col size="12" size-lg="6">
                          <label for="firstName">Nombre*</label>
                          <InputText class="input secondary-input" id="firstName" type="text" v-model="order.billing.name" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="lastName">C.I.F. / D.N.I.*</label>
                          <InputText class="input secondary-input" id="lastName" type="text" v-model="order.billing.cif" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="email">Email*</label>
                          <InputText class="input secondary-input" id="email" type="text" v-model="order.billing.email" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="phone">Teléfono</label>
                          <InputText class="input secondary-input" id="phone" type="text" v-model="order.billing.phone" />
                        </ion-col>
                        <ion-col size="12">
                          <label for="direction">Dirección*</label>
                          <InputText class="input secondary-input" id="direction" type="text" v-model="order.billing.direction" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="city">Ciudad*</label>
                          <InputText class="input secondary-input" id="city" type="text" v-model="order.billing.city" />
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                          <label for="zipCode">Código Postal*</label>
                          <InputText class="input secondary-input" id="zipCode" type="text" v-model="order.billing.zipCode" />
                        </ion-col>
                      </ion-row>

                      <ion-row style="margin-top: 1rem">
                        <ion-col size="12">
                          <label for="notes">Notas de pedido</label>
                          <Textarea v-model="order.no" class="input secondary-input" rows="5" id="notes" placeholder="Notas sobre su pedido, p. Ej. notas especiales para la entrega..." />
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="4">
                <div class="box bg-light2">
                  <h5 style="padding-bottom: 1rem"><strong>Resumen</strong></h5>
                  <ion-list class="carrito">
                    <ion-item v-for="item in cart" :key="item">
                      <ion-label text-wrap>
                        <p style="padding-right: 1rem;"><strong>{{ item.name }}</strong></p>
                        <h6 v-for="variation in item.variations" :key="variation.id">- {{ variation.variation }}: {{ (variation.options.find(x=>x.selected === true)).option }} </h6>
                      </ion-label>
                      <ion-label text-wrap>
                        <p>x{{ item.quantity }}</p>
                        <p>{{ (item.price * item.quantity).toFixed(2) }}€</p>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                  <ion-list lines="full" class="resumen">
                    <ion-item>
                      <ion-label>Subtotal</ion-label>
                      <ion-label>{{ subtotal.toFixed(2) }}€</ion-label>
                    </ion-item>
                    <ion-item>
                      <ion-label>Envío</ion-label>
                      <ion-label>{{ shipping.toFixed(2) }}€</ion-label>
                    </ion-item>
                    <ion-item>
                      <ion-label>Total</ion-label>
                      <ion-label text-wrap>
                        <h3><strong>{{ total.toFixed(2) }}€</strong></h3>
                        <h6>(Incluye {{ tax.toFixed(2) }}€ de I.V.A.)</h6>
                      </ion-label>
                    </ion-item> 
                  </ion-list>
                  <ion-list lines="none" class="resumen">
                    <ion-item class="no-ripple">
                       <ion-checkbox slot="start" v-model="order.terms" color="dark"></ion-checkbox>
                      <h6>He leído y estoy de acuerdo con los <router-link :to="'/'">términos y condiciones</router-link> del sitio web. *</h6>
                    </ion-item>

                    <ion-item class="no-ripple">
                        <ion-checkbox slot="start" v-model="order.privacy" color="dark"></ion-checkbox>
                        <h6>Acepto la <a router-link :to="'/'">Política de Privacidad</a> y consiento el tratamiento de mis datos personales. *</h6>
                    </ion-item>       

                    <ion-item style="margin-bottom: 1rem; margin-top: 0.5rem" class="no-ripple">
                        <ion-checkbox slot="start" v-model="order.commercial" color="dark"></ion-checkbox>
                        <h6>Acepto el uso de mis datos personales para el envío de ofertas, promociones, descuentos e información comercial.</h6>
                    </ion-item>   
                  </ion-list>

                  <ion-button color="secondary" shape="round" expand="full" @click="pagar" :disabled="!order.terms || !order.privacy">
                    <ion-label>Finalizar Compra</ion-label>
                  </ion-button>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonCheckbox } from '@ionic/vue';
import { defineComponent } from 'vue';
import { trashOutline, addOutline, removeOutline } from 'ionicons/icons';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';

import { loadStripe } from '@stripe/stripe-js';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'Checkout',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    IonButton,
    InputText,
    IonCheckbox,
    Textarea,
    Password,
    //Toast
  },
  data () {
      return {
          subtotal: 0,
          total: 0,
          tax: 0,
          shipping: 9,
          order: {
            billing: {},
          },
          createAccount: false,
          newPassword: '',
          billingData: false,
      }
  },
  computed: {
    ...mapState(['cart'])
  },
  methods: {
    ...mapActions(['addOrder', 'cleanCart']),


    recalculate(){
            let subtotal = 0;
            this.cart.forEach(product => {
                subtotal = subtotal + (product.price * product.quantity);
            });

            this.subtotal = subtotal;
            this.total = this.subtotal + this.shipping;
            this.tax = (this.total * 0.21);
    },

    async pagar(){
      this.order.items = this.cart;
      this.order.dateCreated = new Date().getTime();
      this.order.subtotal = this.subtotal;
      this.order.total = this.total;
      this.order.tax = this.tax;
      this.order.shipping = this.shipping;
      this.order.status = 0;
      this.order.payment = {
        method: 'Stripe',
        status: 'Pending'
      };

      const resp = await this.addOrder(this.order);
      this.order.id = resp.id;

      this.cleanCart();

      const stripe = await loadStripe('pk_live_51IxyBFL50umkZplXPAoFqg2YB1E4HQA68gNG1BQU9hLjJXJwixxgbs07zsNcVBo0ydR13Jgqo1BDQrmpxeWsZzZI00Ls4ffpME');

      // Call your backend to create the Checkout Session
      const response = await fetch('https://us-central1-novoinox-com.cloudfunctions.net/openCheckout', { method: 'POST', body: JSON.stringify(this.order) });
      const session = await response.json();

      // When the customer clicks on the button, redirect them to Checkout.
      await stripe.redirectToCheckout({
          sessionId: session.id,
      });
    }
  },
  beforeMount(){
    this.recalculate()
  },
  setup() {
    return {
      trashOutline, addOutline, removeOutline
    }
  }
});
</script>

<style scoped>
  .thumbnail{
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin-right: 1rem;
  }

  .product-item{
    vertical-align: middle;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);
    padding: 1rem 0;
  }

  .product-item ion-item{
    --padding-start: 0;
    --padding-end: 0
  }

  #cart{
      min-height: 75vh;
  }

  .resumen ion-item{  
    --padding-start: 0;
    --padding-end: 0
  }

  .carrito ion-item{  
    --padding-start: 0;
    --padding-end: 0
  }
  
  
</style>